export const environment = {
  cloudimgUrl: '',
  useCloudImg: false,
  api: 'https://mddevapi.flexnow.care/api/patientweb/v1',
  location: {
    origin: 'http://localhost:4200'
  },
  production: false,
  localStorageKey: '123',
  questionCompletedKey: 'questionNaire'
};
