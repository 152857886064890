import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {environment} from "@env/environment";
import { bootstrapApplication } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8') };
  constructor(private httpClient: HttpClient) { }

  public formatErrors(error: any): Observable<any> {
    return throwError(error.error);
  }

  public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get(environment.api+path, {params, ...this.options});
  }
  public post(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .post(environment.api + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }
  public patch(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .patch(environment.api + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }
  public delete(path: string): Observable<any> {
    return this.httpClient.delete(environment.api + path).pipe(catchError(this.formatErrors));
  }

  public deleteWithBody(path: string, body: any): Observable<any> {
    const options = {
      body
    }
    return this.httpClient.delete(environment.api + path, options).pipe(catchError(this.formatErrors));
  }

  public put(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .put(environment.api + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }
}
