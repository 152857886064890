import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PagesComponent} from "@app/pages/pages.component";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
        import("./pages/pages.module").then((m) => m.PagesModule),
    component: PagesComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: "enabled",
    initialNavigation: 'enabledBlocking',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
