import { Injectable } from "@angular/core";
import { ApiService } from "@core/services/api.service";
import { Observable } from "rxjs";
import { QuestionNaire } from "@app/models/question-naire";

const routes = {
  subscriber: "/subscriber",
};

@Injectable({
  providedIn: "root",
})
export class SideApisService {
  constructor(private ApiService: ApiService) {}

  subscription(params: {
    email: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
  }): Observable<any> {
    return this.ApiService.post(routes.subscriber, params);
  }
}
