<div class="header">
    <div class="container">
        <a [routerLink]="['/']" class="logo">
            <img src="/assets/images/logos/logo.png" class="d-inline-block align-top logo" alt="">
            <p class="position-relative slogan">Flex your incredible.</p>
        </a>
        <div (click)="triggerMenu()" class="toogle-menu"><span></span><span></span><span></span></div>
        <div class="bg-menu" [class.show]="show" (click)="show = false"></div>
        <div class="menu" [class.show]="show">
            <span (click)="triggerMenu()" class="close-icon"></span>
            <ul>
                <li class="mobile-show">
                    <a [routerLink]="['/']" (click)="show = false">
                        <i class="icon-menu1"></i><span>Home</span>
                    </a>
                </li>
                <li>
                    <a [routerLink]="['how-it-work']" routerLinkActive="flex-active" [class.flex-active]="$any(hiw1).isActive || $any(hiw2).isActive || $any(hiw3).isActive" id="hiw-menu">
                        <i class="icon-menu2"></i>
                        <span>How it works</span>
                    </a>
                    <ul>
                        <li>
                            <a #hiw1="routerLinkActive" routerLinkActive="flex-child-active" [routerLink]="['how-it-work/science']" (click)="show = false">
                                <span class="mobile-show">-</span> Your Metabolic Health
                            </a>
                        </li>
                        <li>
                            <a #hiw2="routerLinkActive" routerLinkActive="flex-child-active" [routerLink]="['how-it-work/data']" (click)="show = false">
                                <span class="mobile-show">-</span> Flex App
                            </a>
                        </li>
                        <li>
                            <a #hiw3="routerLinkActive" routerLinkActive="flex-child-active" [routerLink]="['how-it-work/coaching']" (click)="show = false">
                                <span class="mobile-show">-</span>
                                Coaching
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a routerLinkActive="flex-active" [routerLink]="['bms-360']" (click)="show = false">
                        <i class="icon-menu3"></i>
                        <span>BMS 360</span>
                    </a>
                </li>
                <li>
                    <a routerLinkActive="flex-active" [routerLink]="['pricing']" (click)="show = false">
                        <i class="icon-menu4"></i><span>Pricing</span>
                    </a>
                </li>
                <li>
                    <a routerLinkActive="flex-active" href="https://www.flexfinance.care/">
                        <i class="icon-menu9"></i><span>Flex Finance</span>
                    </a>
                </li>
                <li>
                    <a routerLinkActive="flex-active" [routerLink]="['faqs']" (click)="show = false">
                        <i class="icon-menu5"></i><span>FAQs</span>
                    </a>
                </li>
                <li>
                    <a routerLinkActive="flex-active" [routerLink]="['why-we-exist']" (click)="show = false">
                        <i class="icon-menu6"></i><span>Why We Exist</span>
                    </a>
                </li>
                <li>
                    <a routerLinkActive="flex-active" [routerLink]="['am-i-eligible']" (click)="show = false;questionNairService.clearLocalQuestionNaireData()">
                        <i class="icon-menu7"></i><span>Am I eligible?</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
