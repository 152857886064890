import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SideApisService } from "@core/services/side-apis.service";
import {Router} from "@angular/router";

@Component({
  selector: "flex-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  emailSubscript: FormGroup;
  loading = false;

  constructor(
    private sideApis: SideApisService,
    private route: Router
  ) {
    this.emailSubscript = new FormGroup<any>({
      email: new FormControl(null, [
        Validators.email,
        Validators.required,
        Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]+$"),
      ]),
    });
  }

  get email(): FormControl {
    return this.emailSubscript.get("email") as FormControl;
  }

  submit() {
    if (!this.emailSubscript.invalid) {
      this.loading = true;
      this.sideApis.subscription(this.emailSubscript.value).subscribe((res) => {
        this.loading = false;
        this.emailSubscript.reset();
        this.route.navigate(['/subscriber'], {
          queryParams: {
            success: true
          }
        });
      });
    } else {
      this.emailSubscript.markAllAsTouched();
    }
  }
}
