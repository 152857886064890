import { Component } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
})
export class PagesComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "flex-fb",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/social/facebook-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "flex-ins",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/images/social/instagram-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "flex-linked",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/images/social/linkedin-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "flex-tiktok",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/images/social/tiktok-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "flex-twitter",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/images/social/twitter-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "flex-youtube",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/images/social/youtube-icon.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "blood",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/blood.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "eat",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/eat.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "walking",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/walking.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "relax",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/images/icons/relax.svg"
      )
    );
  }
}
