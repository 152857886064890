import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagesLazyloadService {
  private observer!: IntersectionObserver;
  constructor() {
    this.init();
  }

  private init() {
    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }
        const lazyImage = entry.target as HTMLImageElement;
        const src: any = lazyImage.dataset['src'];

        lazyImage.tagName.toLowerCase() === 'img' ? lazyImage.src = src : lazyImage.style.backgroundImage = 'url(\'' + src + '\')';

        // xong việc thì nên dọn dẹp
        lazyImage.removeAttribute('lazy');

        // tiếp tục dọn dẹp
        observer.unobserve(lazyImage);
      });
    });
  }

  observe(target: Element) {
    // "trải chiếu nằm chờ" tấm ảnh scroll tới viewport
    this.observer.observe(target);
  }
}
