import { Component } from '@angular/core';
import {QuestionNaireService} from "@core/services/question-naire.service";

@Component({
  selector: 'flex-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  show = false;
  constructor(public questionNairService: QuestionNaireService) {}
  triggerMenu() {
    this.show = !this.show;
  }
}
