/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from '@app/app.module';
import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';

import { environment } from '@env/environment';

if (environment.production) {
  enableProdMode();
}
function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
