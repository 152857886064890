import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {environment} from "@env/environment";
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private route: ActivatedRoute) { }

  getUrlParams(): Observable<Array<KeyValue<string, any>>> {
    return this.route.queryParams.pipe(map((params: Params) => {
      const urlParams: Array<KeyValue<string, any>> = [];
      for (const key of Object.keys(params)) {
        urlParams.push({key: key.toLowerCase(), value: decodeURIComponent(params[key])});
      }
      return urlParams;
    }));
  }
  concatLink(assetLink: string, localPath = false): string {
    if (!assetLink) {
      return '';
    }
    const prefixLocalPath = 'assets/images/';
    const isUrl = new RegExp(/https?:\/\//g).test(assetLink);
    if (!environment.useCloudImg || localPath) {
      return isUrl ? assetLink : `${prefixLocalPath}${assetLink}`;
    }
    const cdnDomain = `${environment.cloudimgUrl}`;
    return cdnDomain + (isUrl ? assetLink : `${environment.location.origin}${prefixLocalPath}${assetLink}`);
  }

  // Merge a `source` object to a `target` recursively
  merge = (target: { [x: string]: any; }, source: { [x: string]: any; }) => {
    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (const key of Object.keys(source)) {
      if (source[key] instanceof Object) { Object.assign(source[key], this.merge(target[key], source[key])); }
    }

    // Join `target` and modified `source`
    Object.assign(target || {}, source);
    return target;
  }
}
