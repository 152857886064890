import {
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
} from "@angular/core";
import { ImagesLazyloadService } from "@shared/images-lazyload/images-lazyload.service";
import { UtilService } from "@core/services/util.service";

@Directive({
  selector:
    "[loading], [brSrc], [backgroundImage], [useLocalPath], [aspect], [hover]",
  host: {
    "[src]": "src",
  },
})
export class ImagesLazyloadDirective {
  @Input() loading!: string;
  @Input() aspect?: boolean;
  @Input() hover?: string;
  @Input() src!: string;

  backupSrc!: string;

  constructor(
    private injector: Injector,
    private el: ElementRef<HTMLElement | HTMLImageElement>,
    private lazyService: ImagesLazyloadService,
    private utilService: UtilService
  ) {}

  isLocalPath = false;

  @Input("useLocalPath")
  set use(is: boolean) {
    this.isLocalPath = is;
  }

  @HostListener("mouseleave", ['$event'])
  @HostListener("mouseenter", ['$event'])
  onMouseEnter($event: MouseEvent) {
    if ($event.type === 'mouseenter') {
      if (this.hover) {
        this.replaceSrc = this.hover;
      }
    }else {
      this.replaceSrc = this.backupSrc;
    }
  }

  @HostListener("load")
  onSetAspect() {
    if (this.el.nativeElement instanceof HTMLImageElement) {
      if (this.aspect) {
        this.el.nativeElement.style.height =
          (this.el.nativeElement.naturalHeight /
            this.el.nativeElement.naturalWidth) *
            this.el.nativeElement.width +
          "px";
        // this.el.nativeElement.style.aspectRatio = this.el.nativeElement.width.toString()+'/'+this.el.nativeElement.height.toString();
      }
    }
  }

  @Input("brSrc")
  set replaceSrc(path: string) {
    if (!this.backupSrc) {
      this.backupSrc = path;
    }
    if (this.loading === "lazy") {
      if ("loading" in HTMLImageElement.prototype) {
        this.src = this.utilService.concatLink(path, this.isLocalPath);
      } else {
        this.callLazy(path);
      }
    } else {
      setTimeout(() => {
        this.src = this.utilService.concatLink(path, this.isLocalPath);
      });
    }
  }

  @Input("backgroundImage")
  set setBackgroundImage(path: string) {
    if (!this.backupSrc) {
      this.backupSrc = path;
    }
    if (this.loading === "lazy") {
      this.callLazy(path);
    } else {
      setTimeout(() => {
        this.el.nativeElement.style.backgroundImage = `url("${this.utilService.concatLink(
          path,
          this.isLocalPath
        )}")`;
      });
    }
  }

  callLazy(path: string) {
    this.el.nativeElement.dataset["src"] = this.utilService.concatLink(
      path,
      this.isLocalPath
    );
    this.lazyService.observe(this.el.nativeElement);
  }
}
