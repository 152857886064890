import {isDevMode, NgModule} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";
import { MatMenuModule } from "@angular/material/menu";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {AppRoutingModule} from "@app/app-routing.module";
import {CustomPreloadingStrategy} from "@core/custom-preloading.strategy";
import {ServiceWorkerModule} from "@angular/service-worker";
import { UrlSerializer } from "@angular/router";
import CustomUrlSerializer from "./core/serializer/custom.serializer";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    MatMenuModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,//!isDevMode()
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    CustomPreloadingStrategy
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
