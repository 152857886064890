import {PreloadingStrategy, Route} from "@angular/router";
import {Observable, of} from "rxjs";

export class CustomPreloadingStrategy implements PreloadingStrategy {
  preloadedModules: string[] = [];
  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload']) {
      if (route.path != null) {
        this.preloadedModules.push(route.path);
      }
      return fn();
    }else {
      return of(null);
    }
  }

}
