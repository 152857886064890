<footer class="flex-footer">
    <div class="container">
        <div class="row align-items-end text-white">
            <div class="col-auto me-xl-5 me-0">
                <div class="logo-mark">
                    <img style="height: 50px" brSrc="logos/logo-white.png" class="d-inline-block align-top logo" alt=""/>
                    <p style="margin-left: 5px;margin-top: -10px; font-size: 12px;font-weight: 400 !important;"
                       class="position-relative slogan ff-ProximaNova text-white">
                        Flex your incredible.
                    </p>
                </div>
                <div class="social">
                    <a href="https://www.tiktok.com/@flexnowofficial" target="_blank" mat-icon-button
                       aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon svgIcon="flex-tiktok"></mat-icon>
                    </a>
                    <a href="https://www.instagram.com/flexnowofficial" target="_blank" mat-icon-button
                       aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon svgIcon="flex-ins"></mat-icon>
                    </a>
                    <a href="https://www.facebook.com/flexnowofficial" target="_blank" mat-icon-button
                       aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon svgIcon="flex-fb"></mat-icon>
                    </a>
                    <a href="https://www.youtube.com/@flexnowofficial" target="_blank" mat-icon-button
                       aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon svgIcon="flex-youtube"></mat-icon>
                    </a>
                    <a href="https://twitter.com/flexnowofficial" target="_blank" mat-icon-button
                       aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon svgIcon="flex-twitter"></mat-icon>
                    </a>
                </div>
            </div>
            <div class="col">
                <div class="row links">
                    <div class="col-4 ff-Brother-1816-book">
                        <h6 class="ff-Brother-1816-book text-uppercase mb-3">COMPANY</h6>
                        <p>
                            <a href="https://www.revival.care/about-us" target="_blank" class="text-reset">About Us</a>
                        </p>
                        <p>
                            <a class="text-reset">Support</a>
                        </p>
                    </div>
                    <div class="col-4 ff-Brother-1816-book">
                        <h6 class="ff-Brother-1816-book text-uppercase mb-3">LEGAL</h6>
                        <p>
                            <a href="https://flexnow.care/term_of_service.html" target="_blank" class="text-reset">Terms of Use</a>
                        </p>
                        <p>
                            <a href="https://flexnow.care/privacy_policy.html" target="_blank" class="text-reset">Privacy Policy</a>
                        </p>
                    </div>
                    <div class="col-4 ff-Brother-1816-book">
                        <h6 class="ff-Brother-1816-book text-uppercase mb-3">RESOURCES</h6>
                        <p>
                            <a href="/faqs" class="text-reset">FAQs</a>
                        </p>
                        <p>
                            <a href="mailto:support@flexnow.care" class="text-reset">Contact Us</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col col-newsletter d-none">
                <form [formGroup]="emailSubscript" (ngSubmit)="submit()" class="regis-email align-items-end d-flex">
                    <div class="col me-2">
                        <h6 class="text-uppercase ff-Brother-1816-book text-center mb-4">
                            STAY IN THE LOOP
                        </h6>
                        <div class="form-outline ff-Brother-1816-book form-white">
                            <input [class.is-invalid]="email.invalid && email.touched" formControlName="email"
                                   placeholder="Enter your email" type="email" class="form-control"/>
                        </div>
                    </div>
                    <div class="col-auto">
                        <button type="submit" [disabled]="loading" mat-ripple class="btn btn-dark flex-btn fw-bold">
                            Sign Up
                            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                                  aria-hidden="true"></span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</footer>
