import { Injectable } from "@angular/core";
import { ApiService } from "@core/services/api.service";
import { Observable } from "rxjs";
import {
  QuestionNaire,
  QuestionNairePostData,
  QuestionTagMedication,
  StateInfo,
} from "@app/models/question-naire";
import { LocalService } from "@core/services/local.service";
import { HttpParams } from "@angular/common/http";

const routes = {
  questionNaire: "/questionnaire",
  tagMedication: "/medication",
  state: "/metadata/states",
};

@Injectable({
  providedIn: "root",
})
export class QuestionNaireService {
  questionDataKey = "question-data";
  questionFinal = "question-final";

  constructor(
    private ApiService: ApiService,
    private localService: LocalService
  ) {}

  getQuestionNaire(): Observable<QuestionNaire[]> {
    return this.ApiService.get(routes.questionNaire);
  }

  postQuestionNaire(params: QuestionNairePostData[]): Observable<any> {
    return this.ApiService.post(routes.questionNaire, params);
  }

  getTagMedication(params: HttpParams): Observable<QuestionTagMedication[]> {
    return this.ApiService.get(routes.tagMedication, params);
  }

  getStates(): Observable<StateInfo[]> {
    return this.ApiService.get(routes.state);
  }

  storeLocalQuestionNaireData(params: QuestionNairePostData) {
    const data: QuestionNairePostData[] = JSON.parse(
      this.localService.getData(this.questionDataKey) || "[]"
    );
    if (data.some((d) => d.questionId === params.questionId)) {
      data[data.findIndex((d) => d.questionId === params.questionId)] = params;
    } else {
      data.push(params);
    }
    this.localService.saveData(this.questionDataKey, JSON.stringify(data));
  }

  getLocalQuestionNaireData(): QuestionNairePostData[] | [];
  getLocalQuestionNaireData(id: number): QuestionNairePostData | undefined;
  getLocalQuestionNaireData(
    id?: number
  ): QuestionNairePostData | QuestionNairePostData[] | undefined {
    const data: QuestionNairePostData[] = JSON.parse(
      this.localService.getData(this.questionDataKey) || "[]"
    );
    if (id) return data.find((d) => d.questionId === id);
    return data;
  }

  clearLocalQuestionNaireData() {
    this.localService.removeData(this.questionDataKey);
  }

  storeFinalQuestion(finalQuestion: any) {
    this.localService.saveSessionData(
      this.questionFinal,
      JSON.stringify(finalQuestion)
    );
  }

  getFinalQuestion() {
    return JSON.parse(
      this.localService.getSessionData(this.questionFinal) || "null"
    );
  }
}
