import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class LocalService {
  private key = environment.localStorageKey;

  constructor() {}

  public saveData(key: string, value: string) {
    localStorage.setItem(key, this.encrypt(value));
  }

  public saveSessionData(key: string, value: string) {
    sessionStorage.setItem(key, this.encrypt(value));
  }

  public getData(key: string) {
    let data = localStorage.getItem(key) || "";
    return this.decrypt(data);
  }

  public getSessionData(key: string) {
    let data = sessionStorage.getItem(key) || "";
    return this.decrypt(data);
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public removeSessionData(key: string) {
    sessionStorage.removeItem(key);
  }

  public clearData(): void {
    localStorage.clear();
  }

  public clearSessionData(): void {
    sessionStorage.clear();
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(
      CryptoJS.enc.Utf8
    );
  }
}
